.listItemContainer {
  width: 220px !important;
}
.listItem {
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
}
.listItem p {
  font-size: 14px;
  font-weight: 450;
  padding-top: 10px;
  margin: 0;
}
