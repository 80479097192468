.footerMainDiv {
  color: #fff;
  width: 100%;
  height: 174px;
  background-color: #03204c;
  position: fixed;
  bottom: 0;
}
.footerItem {
  padding: 4px;
}
.footerItem p,
.footerItem h4 {
  margin: 0;
  padding: 0;
}
.companyDetailDiv {
  display: flex;
  height: 100px;
  height: 100px;
  flex-direction: row;
}
.upperDiv {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lowerDiv {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.copyRight {
  color: #63666c;
  padding-left: 10%;
  padding-right: 10%;
}
