.contentLayout {
  display: flex;
  overflow: auto;
  height: calc(100% - 174px);
}
.contentsArea {
  width: 100%;
  height: 100%;
  text-align: center;
}
.pageHead {
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
}
.changeable {
  background-color: #fff;
}
.title {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.BillHeader {
  display: flex;
}
.BillHeaderDiv {
  margin-bottom: 20px;
}
.fieldTitleDiv {
  font-size: 16px;
  font-weight: 600;
}
.fieldTitle {
  vertical-align: -webkit-baseline-middle;
  font-size: 14px;
  font-weight: 600;
  text-align: end;
}
.fieldContent {
  width: 65%;
}
.fieldHead {
  width: 25%;
  padding-left: 10px;
}
.fieldInput {
  width: 50%;
}
.fieldButtons {
  width: 25%;
  margin: 2px;
  margin-left: 25px;
}
.headingDiv {
  padding-top: 10px;
}
.PlanPaper {
  width: 30%;
}
.planTitle {
  font-size: 24px;
  font-weight: 600;
}

.price {
  font-size: 30px;
  font-weight: 600;
}
.rupeeSymbol {
  font-size: 16px;
  color: cadetblue;
}
.perYear {
  font-size: 14px;
  font-weight: 600;
  color: #a0a7b4;
}
.mainGrid {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  display: flex;
}
/* payment callback pages */
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymentCard {
  width: 20em;
  padding: 15px;
}
.centerText {
  text-align: center;
}
