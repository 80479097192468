.columnCell {
  font-weight: 700 !important;
}
.btn {
  border: 1px solid rgba(0, 0, 1, 0.1) !important;
}
.image {
  min-height: 50px;
  max-height: 50px;
  background-size: contain !important;
  margin: 5px;
  justify-content: center;
}

.positiveValue {
  color: green !important;
}
.negativeValue {
  color: red !important;
}
.tableRow {
  position: relative;
}
.coverParentFull {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #79090987;
  height: 100%;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableFilter {
  padding-left: 0 !important;
  padding-right: 5px !important;
  cursor: pointer !important;
  color: #555555 !important;
}
