.contentWrapper {
  padding: 10px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}
/* changeable section */
.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}
.subTitle {
  font-size: 18px;
  font-weight: 500;
}
/* filter section */
.filterSec {
  display: flex;
  justify-content: space-between;
}

.filerInputSec {
  display: flex;
}
/* action button section */
.actionBtn {
  font-size: 12px !important;
  text-align: center;
  font-weight: 400;
  margin-right: 5px !important;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}
.marginTop {
  margin-top: 4px;
}



.settingsGrid {
  width: calc(100% - 20px);
  border-radius: 5px;
  height: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #fff;
}
.subHeader {
  margin: 10px 0 !important;
}
.subHeader span {
  font-size: 15px;
  font-weight: 700;
}
.BillHeader {
  display: flex;
}
.fieldTitle {
  vertical-align: -webkit-baseline-middle;
  font-size: 14px;
  font-weight: 600;
  text-align: end;
  text-transform: capitalize;
  padding-left: 8px;
}
.button {
  margin: 10px !important;
  padding: 5px 40px !important;
}
.BillHeader {
  display: flex;
}
.buttonWrapper {
  display: flex;
  padding-top: 25px !important;
}