.contentWrapper {
  padding: 10px;
}
.titleSec {
  padding-bottom: 10px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.menuName {
  font-size: 14px;
  font-weight: 300px;
  padding-left: 5px;
  color: gray;
}
.priceCard {
  min-height: 105px;
  max-height: 105px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
}
.price {
  font-size: 30px;
  color: white;
  align-items: center;
}
.count {
  z-index: 3;
  margin-left: 5px;
}
.category {
  font-size: 15px;
  color: white;
}
.summaryLabelBig {
  font-size: 18px;
  color: white;
}
.iconSec {
  position: absolute;
  top: 10px;
  right: 10px;
}
.icon {
  font-size: 40px !important;
  color: #666;
  opacity: 0.5;
}

.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
}
.chartTitle {
  font-size: 18px;
}
.filterTitle {
  padding-bottom: 15px;
  font-weight: 400;
}
.invoicesDetails {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.coverParentFull {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
