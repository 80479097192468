.contentWrapper {
  padding: 10px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  flex: auto;
}

.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}

.titleSec {
  display: flex;
}

.menuList {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filterEnd {
  display: flex;
  align-items: center;
}

/* changeable section */

.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 1px;
}

.subTitle {
  font-size: 18px;
  font-weight: 500;
}

/* filter section */

.filterSec {
  display: flex;
  justify-content: space-between;
}

.filterDiv {
  margin-right: 15px;
}

.filerInputSec {
  display: flex;
}

/* search input section */

.searchSec {
  padding: 8px;
  margin-left: 20px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 1, 0.1);
  border-radius: 6px;
}

.serachSec:focus-within {
  border-color: black;
}

.searchInput {
  border: none;
  width: 80%;
  font-size: 17px;
}

.searchInput::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 1, 0.3);
}

.searchInput:focus-visible {
  outline: none;
}

.searchIcon {
  font-size: 28px;
  cursor: pointer;
}

/* action button section */

.actionButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
  /* margin: 20px 0 10px; */
}

.actionBtn {
  font-size: 12px !important;
  font-weight: 400;
}

.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}

.inputLabelContainer {
  display: flex;
}

.inputLabel {
  font-size: 15px;
  font-weight: 600;
  padding-right: 30px;
  text-align: end;
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.inputRow {
  margin-bottom: 10px;
}

.columnCell {
  font-weight: 700 !important;
}

.nameColumn {
  font-weight: 700 !important;
  width: 25%;
}

.submitSection {
  margin: 15px;
  margin-left: 0;
  justify-content: flex-end;
}

.link {
  text-decoration: none;
}

.closeBtn {
  margin-right: 10px !important;
}

.marginTop {
  margin-top: 4px;
}

.normalText {
  margin: 0 8px;
}

.tableContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  position: relative !important;
}
