.menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.more-options-container {
  display: flex;
  justify-content: flex-end;
}

.menu-list {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 1rem;
}

.menu-item {
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
  border-bottom: 1px solid #ddd;
}

.menu-item:hover {
  border: 1px solid #6b6b6b;
  transform: scale(1.03);
}

.menu-item:last-child {
  border-bottom: none;
}

.iconBtn {
  cursor: pointer;
  color: gray;
}
