.contentWrapper {
  padding: 10px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.menuTitle {
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
  color: gray;
}
/* changeable section */
.changeable {
  background-color: #fff;
  border-top: 1px solid #d2d6de;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}
.subTitle {
  font-size: 18px;
  font-weight: 500;
}
/* filter section */
.filterSec {
  display: flex;
  justify-content: space-between;
}

.filerInputSec {
  display: flex;
}
/* action button section */
.actionBtn {
  font-size: 12px !important;
  text-align: center;
  font-weight: 400;
  margin-right: 5px !important;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}
.marginTop {
  margin-top: 4px;
}
