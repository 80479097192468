.contentWrapper {
  min-width: 350px;
  max-width: 100%;
  width: 70%;
  background-color: white;
  border-top: 1px solid green;
  border-radius: 5px;
  margin: auto;
  margin-right: 5px;
  margin-left: 5px;
  /* max-height: calc(100% - 50px); */
}
.fullPageWrapper {
  padding: 10px;
  min-width: 350px;
  max-width: 100%;
  width: 90%;
  background-color: white;
  border-top: 1px solid green;
  border-radius: 5px;
  margin: auto;
  margin-right: 5px;
  margin-left: 5px;
  /* max-height: calc(100% - 50px); */
}
.contentWrapper::-webkit-scrollbar {
  height: 0;
}
.contentWrapper::-webkit-scrollbar-thumb {
  width: 0;
}
.headSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 1, 0.1);
}
.subHeader {
  display: flex;
  padding: 15px;
  height: 30px;
}
.subSectionHeader {
  padding: 5px 10px !important;
  display: flex;
  justify-content: space-between;
}
.footSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 1, 0.1);
}
.changeable {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}
.tableContent {
  padding: 8px;
  max-height: 440px;
  overflow-y: auto;
}
.tableContent1 {
  padding: 8px;
  max-height: 300px;
  height: fit-content !important;
  overflow-y: auto;
}
.tableContent2 {
  padding: 8px;
  max-height: 260px;
  height: fit-content !important;
  overflow-y: auto;
}
.tableContent3 {
  padding: 8px;
  max-height: fit-content;
  height: fit-content !important;
  overflow-y: auto;
}
.editTitle {
  font-size: 14px;
  font-weight: 500;
}
.tableName {
  font-size: 14px;
  font-weight: 500;
}
.customerData {
  font-size: 16px;
  font-weight: 500;
}
.inputSec {
  padding: 10px;
  max-height: 440px;
  overflow-y: auto;
}
.content {
  padding: 10px 25px;
}
.inputSecAllEdit {
  border-radius: 5px;
  background-color: #ecf0f5;
  padding: 10px;
  max-height: 440px;
  overflow-y: auto;
}

.inputLabelContainer {
  display: flex;
}
.inputLabel {
  font-size: 13px;
  font-weight: 600;
  padding-right: 30px;
  text-align: end;
}
.inputSubLabel {
  font-size: 13px;
  font-weight: 600;
  color: #707070;
}
.subContainer {
  background-color: rgba(202, 207, 216, 0.1) !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.textValues {
  font-size: 13px;
  font-weight: 500;
}
.textHead {
  font-size: 13px;
  font-weight: 500;
  color: #555555;
}
.inputRow {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
  padding-left: 15px;
}
.inputData {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.closeBtn {
  margin-right: 10px !important;
}
.uploadBtn {
  background-color: #fc9a02 !important;
  color: white !important;
}
.image {
  min-height: 100px;
  width: 40%;
  padding: 8px;
  margin: 15px 8px;
  background-size: contain !important;
}
.imageContainer {
  display: flex;
  justify-content: flex-start;
}
.numberInput input::-webkit-inner-spin-button,
.numberInput input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.subSection {
  padding: 8px;
  margin-bottom: 0;
}
/* account switcher section */
.selectedProfile {
  flex-direction: column;
  text-align: center !important;
}
.customerDetails {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 8px;
}
.addShopBtn {
  display: flex;
  width: 100%;
}
.addShopIcon {
  margin-right: 5px;
  color: #fff;
}
.actionBtnIcon {
  margin-right: 6px;
  font-size: 16px !important;
}
.popUp {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px 0px;
  z-index: 2;
}
.categoryImg {
  background-size: contain !important;
  background-position: left !important;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}
.imageSizeWarning {
  font-size: 14px;
  font-style: italic;
  padding-bottom: 10px;
  color: #f00;
}

.icon {
  cursor: pointer;
}

.defaultText {
  font-size: 14px;
  font-style: italic;
  color: #767676;
}
.messageHead {
  font-size: 22px;
  color: #222;
  font-weight: 600;
  font-style: oblique;
}
.message {
  font-size: 16px;
  color: #575757;
  line-height: 20px;
}
.selectDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchSec {
  padding: 5px;
  min-width: 270px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 1, 0.1);
  border-radius: 20px;
  height: 25px;
  border-radius: 6px;
}
.serachSec:focus-within {
  border-color: black;
}
.searchInput {
  border: none;
  width: 80%;
  font-size: 16px;
}
.searchInput::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 1, 0.3);
}
.searchInput:focus-visible {
  outline: none;
}
.searchIcon {
  font-size: 28px;
}
.link {
  cursor: pointer;
  font-size: 16px !important;
  color: #1d57ac;
  font-weight: 600 !important;
}
.packCotainer {
  height: 180px;
  width: 200px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}
.packWrapper {
  display: flex;
  justify-content: center;
}
.selectedPackContainer {
  background-color: #3969c91a !important;
}
.packCotainer:hover {
  cursor: pointer;
  background-color: #3969c91a !important;
}
.packCotainer img {
  height: 80px;
  width: 80px;
}
.packCotainer p {
  font-size: 13px;
  font-style: italic;
  color: gray;
}
.subText {
  padding-left: 5px;
  font-size: 14px;
  color: gray;
  font-style: italic;
  line-height: 20px;
}
.subLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 450;
}
.subTextSec {
  font-size: 13px;
  color: gray;
  font-style: italic;
  line-height: 16px;
}
.headContainer {
  display: flex !important;
}
.packWrapper {
  font-size: 13px;
}
.errorMsg {
  color: #b82020;
  font-size: 13px;
}
.successMsg {
  color: green;
  font-size: 13px;
}
.text {
  font-size: 13px;
  color: #222;
  padding: 4px 15px;
  font-weight: 450;
}

.qrContainer {
  border: 10px solid #0062c7;
  border-radius: 15px;
  height: fit-content;
  max-width: 420px !important;
  padding: 10px 40px;
}
.logo {
  background-size: contain !important;
  width: 100%;
  height: 100px;
}
.logoHead {
  font-size: 28px;
  color: #07396d;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.textSub {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.7;
  text-align: center;
}
a {
  cursor: pointer;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.checkboxLabel {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}